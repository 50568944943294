/** @format */

import React from "react";

export default function Antrix2k19() {
	return (
		<div className="Antrixx2k19 text-center componentHeight">
			<h1 className="my-5">Antrix2k19</h1>
		</div>
	);
}
