/** @format */

import React from "react";
import "../events.css";

export default function Fusion2k21() {
	// function importAll(r) {
	// 	var images = [];
	// 	r.keys().map((item) => {
	// 		images.push(r(item));
	// 	});
	// 	return images;
	// }

	// var images = importAll(require.context('./images', false));
	return (
		<div className="Fusion2k21 text-center componentHeight">
		<h1 className="my-5">Fusion2k21</h1>
			{/* <div className="container photo-gallery my-5">
				<ul className="auto-grid">
					{images.map((img, index) => {
						return (
							<li className="photo" key={index}>
								<img src={img} />
							</li>
						);
					})}
				</ul>
			</div> */}
		</div>
	);
}
